.video {
  padding: 70px 0 80px;
  background-color: $light-gray;

  &__container {
    @extend .container;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__video {
    width: 100%;
    height: 560px;
    max-width: 1000px;
  }
  @media screen and (max-width: 768px) {
    &__video {
      height: 400px;
    }
  }
  @media screen and (max-width: 550px) {
    &__video {
      height: 290px;
    }
  }
  @media screen and (max-width: 400px) {
    &__video {
      height: 200px;
    }
  }
}