@mixin font-style($family, $size: inherit, $color: inherit) {
    font-family: $family;
    font-size: $size;
    color: $color;
}

@mixin beveled-corners($bg, $tl: 0, $br: $tl) {
    background: $bg;
    background: linear-gradient(115deg, transparent $tl, $bg 0) top left,
    linear-gradient(-65deg, transparent $br, $bg 0) bottom right;
    background-size: 51% 100%;
    background-repeat: no-repeat;
}

@mixin background-effects($color) {
    background-color: $color;
    &:hover {
        background-color: lighten($color, 5%);
    }
    &:focus {
        background-color: darken($color, 5%);
    }
}

@mixin size($size) {
    width: $size;
    height: $size;
}
