.container {
    width: 100%;
    height: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
}

.link-default-styles {
    cursor: pointer;
    opacity: 0.6;
    transition: 0.3s ease;
    &:hover {
        opacity: 1;
    }
}
