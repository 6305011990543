.burger-menu {
  @include size(40px);

  position: relative;
  display: none;
  cursor: pointer;
  transition: 0.3s ease;
  &:hover {
    transform: shake;
  }

  &__item {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 2px;
    background-color: $white;
    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      width: 20px;
      height: 2px;
      background-color: $white;
      transition: 0.3s ease;
    }
    &:before {
      top: -7px;
    }
    &:after {
      top: 7px;
    }
  }
  &--active {
    .burger-menu {
      &__item {
        height: 0;
        &:before {
          top: 0;
          transform: rotate(225deg);
        }
        &:after {
          top: 0;
          transform: rotate(-225deg);
        }
      }
    }
  }
  @media (max-width: 1023px) {
    display: block;
  }
}
