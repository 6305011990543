.lira {
  padding: 120px 0 120px;
  background-color: $light-gray;

  &__container {
    @extend .container;

    text-align: center;
  }
  &__title {
    @include font-style($open-sans-regular, 34px, $black);

    font-weight: 900;
    margin-bottom: 60px;
    text-transform: uppercase;
  }
  &__wrap {
    display: flex;
    gap: 40px;
  }
  &__text-wrap {
    width: 50%;
    text-align: left;
  }
  &__description {
    @include font-style($open-sans-regular, 18px, $black);

    margin-bottom: 30px;
    line-height: 32px;
  }
  &__list-item {
    @include font-style($open-sans-regular, 16px, $gray);

    position: relative;
    padding-left: 15px;
    line-height: 24px;
    &:before {
      @include size(6px);

      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      background-color: $gray;
      border-radius: 50%;
    }
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
  &__photo-wrap {
    width: 50%;
  }
  &__link {
    display: inline-block;
    width: 50%;
    margin: 0 auto;
    &:not(:first-child) {
      display: none;
    }
    &:hover {
      cursor: pointer;
      .lira {
        &__img {
          transform: scale(1.1);
        }
      }
    }
  }
  &__img {
    object-fit: cover;
    transition: .3s ease;
  }

  @media (max-width: 1023px) {
    &__wrap {
      flex-direction: column;
    }
    &__text-wrap,
    &__photo-wrap {
      width: 100%;
    }
    &__text-wrap {
      margin-bottom: 40px;
    }
  }
  @media (max-width: 767px) {
    &__text-wrap {
      flex-direction: column;
      align-items: center;
    }
    &__list,
    &__description {
      width: 100%;
    }
  }
}