.composite {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;

    &__title {
        @include font-style($open-sans-regular, 44px, $white);
        @include beveled-corners($red, 30px);

        text-transform: uppercase;
        width: 600px;
        padding: 10px;
        text-align: center;
    }
    &__image {
        position: absolute;
        z-index: -1;
        width: 100vw;
        height: 100vh;
        object-fit: cover;
    }
    @media screen and (max-width: 550px) {
        &__title {
            font-size: 34px;
        }
    }
}
