.characteristics {
    padding: 120px 0 120px;

    &__container {
        @extend .container;
    }
    &__title {
        @include font-style($open-sans-regular, 34px, $black);

        margin-bottom: 60px;
        text-align: center;
        font-weight: 900;
    }
    &__description {
        @include font-style($open-sans-regular, 28px, $black);

        margin-bottom: 60px;
        text-align: center;
    }
    @media (max-width: 790px) {
        &__wrap {
            overflow-x: scroll;
        }
    }
}
