.projects {
    padding: 120px 0 120px;

    &__container {
        @extend .container;

        display: flex;
        flex-direction: column;
    }
    &__title {
        @include font-style($open-sans-regular, 34px, $black);

        text-align: center;
        margin-bottom: 60px;
    }
    &__wrap {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 40px;
    }
    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 270px;
        text-align: center;
        &:hover {
            cursor: pointer;
            .projects {
                &__img {
                    transform: scale(1.1);
                }
            }
        }
    }
    &__heading {
        @include font-style($open-sans-regular, 24px, $black);

        line-height: 24px;
    }
    &__inner {
        margin-bottom: 20px;
    }
    &__link {
        overflow: hidden;
        &:not(:first-child) {
            display: none;
        }
    }
    &__img {
        @include size(200px);

        transition: .2s;
        overflow: hidden;
        border-radius: 50%;
    }
}