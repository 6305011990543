.modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, .4);
  opacity: 0;
  pointer-events: none;
  transition: .3s;

  &__content {
    padding: 20px 40px;
    border-radius: 12px;
    background-color: $white;
    transform: scale(0.5);
    transition: .3s all;
  }
  &__title {
    @include font-style($open-sans-regular, 34px, $black);

    margin-bottom: 30px;
    text-align: center;
  }
  &__item {
    @include font-style($open-sans-regular, 20px, $gray);

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
  &__link {
    margin-left: 12px;
    font-size: 22px;
    color: $black;
    border-bottom: 1px solid transparent;
    transition: .3s;
    &:hover {
      border-color: $black;
    }
  }
  &--active {
    overflow: hidden;
    opacity: 1;
    pointer-events: all;
    .modal {
      &__content {
        transform: scale(1);
      }
    }
  }
  @media screen and (max-width: 550px) {
    &__link {
      font-size: 16px;
    }
  }
}