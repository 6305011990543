@font-face {
    font-family: 'Open-Sans-regular';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/open-sans-v29-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/open-sans-v29-latin-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/open-sans-v29-latin-regular.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/open-sans-v29-latin-regular.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/open-sans-v29-latin-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/open-sans-v29-latin-regular.svg#OpenSans')
    format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'Open-Sans-semibold';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/open-sans-v29-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/open-sans-v29-latin-600.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/open-sans-v29-latin-600.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/open-sans-v29-latin-600.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/open-sans-v29-latin-600.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/open-sans-v29-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}