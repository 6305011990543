.table {
    display: flex;
    width: 100%;
    min-width: 770px;

    &__head {
        display: flex;
    }
    &__head-item {
        @include font-style($open-sans-regular, 20px, $white);

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1 1;
        padding: 20px;
        line-height: 24px;
        text-align: center;
        background-color: $red;
    }
    &__col {
        display: flex;
        flex-direction: column;
        width: 20%;
        text-align: center;
    }
    &__row-item {
        @include font-style($open-sans-semibold, 16px, black);
        padding: 20px;
        border-bottom: 1px solid gray;
    }
}
