.product {
    padding: 120px 0 120px;

    &__container {
        @extend .container;

    }
    &__title {
        @include font-style($open-sans-regular, 34px, $black);

        margin-bottom: 60px;
        text-align: center;
        text-transform: uppercase;
        font-weight: 900;
    }
    &__cards {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
}
