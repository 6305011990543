.product-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 260px;
    margin: 15px;
    text-align: center;

    &__logo {
        @include size(100px);

        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 20px;
        background-color: #c5edec;
        border-radius: 20px;
    }
    &__logo-img {
        @include size(60px);
    }
    &__title {
        @include font-style($open-sans-regular, 18px, $black);

        margin-bottom: 15px;
        font-weight: 900;
    }
    &__description {
        @include font-style($open-sans-regular, 16px, $gray);

        line-height: 24px;
        font-weight: 500;
    }
}
