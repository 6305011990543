.header {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 999;
    height: 75px;
    background-color: #222;

    &__container {
        @extend .container;

        display: flex;
        justify-content: space-between;
        align-items: center;

    }
    &__logo {
        @include font-style($open-sans-regular, 24px, $white);

        text-transform: uppercase;
    }
    &__nav {
        display: flex;
        align-items: center;
        gap: 30px;
    }
    &__nav-list {
        display: flex;
        gap: 30px;
    }
    &__nav-link {
        @include font-style($open-sans-regular, 20px, $white);

        display: block;
        padding: 16px 0;
        transition: .3s;
        &:hover {
            color: #6dc9c9;
        }
    }
    &__language-box {
        display: flex;
    }
    &__language-button {
        @include font-style($open-sans-regular, 20px, $white);

        display: block;
        padding: 16px 0;
        text-transform: uppercase;
        cursor: pointer;
        transition: .3s;
        &:hover {
            color: #6dc9c9;
        }
        &:not(:last-child) {
            margin-right: 15px;
        }
    }

    @media (max-width: 1023px) {
        &__nav {
            position: absolute;
            top: 75px;
            left: 0;
            right: 0;
            transform: translateX(-100%);
            justify-content: space-between;
            align-items: flex-start;
            padding: 40px;
            transition: 0.3s ease;
            background-color: $black;
            &--mobile-open {
                transform: translateX(0);
            }
        }
        &__nav-list {
            flex-direction: column;
        }
    }
}
