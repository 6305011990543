.specification {
    padding: 120px 0 120px;
    background-color: $light-gray;

    &__title {
        @include font-style($open-sans-regular, 34px, $black);

        text-align: center;
        margin-bottom: 60px;
    }
    &__container {
        @extend .container;

        display: flex;
        flex-wrap: wrap;
        gap: 60px;
        justify-content: center;
    }
    &__link {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 200px;
        text-align: center;
        cursor: pointer;
        &:hover {
            .specification {
                &__logo {
                    transform: scale(1.1);
                }
            }
        }
    }
    &__logo {
        @include size(200px);

        margin-bottom: 30px;
        border-radius: 50%;
        border: 2px solid transparent;
        overflow: hidden;
        transition: .3s;
    }
    &__img {
        @include size(200px);

        border-radius: 50%;
        background-size: cover;
        transition: .3s;
        object-fit: cover;
    }
    &__name {
        @include font-style($open-sans-regular, 20px, $black);

        transition: .3s;
        line-height: 24px;
    }
}