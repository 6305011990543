* {
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
}

body,
html {
    min-width: 100%;
    min-height: 100%;
    line-height: 1;
    text-transform: none;
    visibility: visible;
}
ol,
ul {
    list-style: none;
}

img,
fieldset,
a img {
    border: none;
}

img {
    display: block;
    width: 100%;
    height: auto;
}

a {
    text-decoration: none;
    &:active,
    &:hover {
        outline: none;
        text-decoration: none;
    }
}
input,
button {
    margin: 0;
    padding: 0;
    background: transparent;
    border: 0;
}
*:focus {
    outline: none;
}
